import Bugsnag from '@bugsnag/js';
import qs from 'query-string';
import { buildApiUrl } from '../../helpers/urls';
import { alphabeticalProvidedServices } from './provided-services';

export const CompanyTypeOptions = [
  {
    key: 'general_contractor',
    label: 'General Contractors',
    urlKey: 'general-contractors',
    type: 'companyType',
  },
  {
    key: 'specialty_contractor',
    label: 'Specialty Contractors',
    urlKey: 'specialty-contractors',
    type: 'companyType',
  },
  {
    key: 'architect',
    label: 'Architects',
    urlKey: 'architects',
    type: 'companyType',
  },
  {
    key: 'engineer',
    label: 'Engineers',
    urlKey: 'engineers',
    type: 'companyType',
  },
  {
    key: 'supplier',
    label: 'Suppliers',
    urlKey: 'suppliers-and-vendors',
    type: 'companyType',
  },
  {
    key: 'owner_real_estate_developer',
    label: 'Owners Real Estate Developers',
    urlKey: 'owner-real-estate-developers',
    type: 'companyType',
  },
  {
    key: 'consultant',
    label: 'Consultants',
    urlKey: 'consultants',
    type: 'companyType',
  },
];

export const ConstructionSectorFilters = [
  {
    key: 'commercial',
    label: 'Commercial',
    urlKey: 'commercial',
    type: 'constructionSector',
  },
  {
    key: 'healthcare',
    label: 'Healthcare',
    urlKey: 'healthcare',
    type: 'constructionSector',
  },
  {
    key: 'institutional',
    label: 'Institutional',
    urlKey: 'institutional',
    type: 'constructionSector',
  },
  {
    key: 'residential',
    label: 'Residential',
    urlKey: 'residential',
    type: 'constructionSector',
  },
  {
    key: 'infrastructure',
    label: 'Infrastructure',
    urlKey: 'infrastructure',
    type: 'constructionSector',
  },
  {
    key: 'industrial_and_energy',
    label: 'Industrial and Energy',
    urlKey: 'industrial-and-energy',
    type: 'constructionSector',
  },
];

export const TradeFilters = alphabeticalProvidedServices.map(
  ({ name, urlKey }) => ({
    key: urlKey.replace(/-/g, '_'),
    label: name,
    urlKey,
    type: 'trade',
  })
);

export const SortBy = [
  {
    key: 'relevance',
    label: 'Relevance',
    urlKey: 'relevance',
    type: 'sortBy',
  },
  {
    key: 'distance',
    label: 'Distance',
    urlKey: 'distance',
    type: 'sortBy',
  },
];

export const RADIUS_FILTER = {
  key: 'radius',
  label: 'Radius',
  type: 'radius',
  disabled: true,
};

export const fetchValidFilters = async (
  trades: string,
  sectors: string,
  types: string,
  classifications: string
) => {
  const query = qs.stringify({
    trades,
    sectors,
    types,
    classifications,
  });
  const response = await fetch(buildApiUrl(`filters?${query}`));
  if (!response.ok) {
    const text = await response.text();
    Bugsnag.notify(
      new Error(`Failed to fetch valid filters: ${response.status} ${text}`)
    );
    return {
      trade: [],
      constructionSector: [],
      companyType: [],
      classifications: [],
    };
  }
  return response.json();
};
