import qs from 'query-string';
import Bugsnag from '@bugsnag/js';
import getConfig from 'next/config';
import { NextApiRequest } from 'next';
import {
  matchLevelZoomReferences,
  openStreetPolygonParser,
} from '../helpers/polygon-parser';
import { findStateInCountry, StateType } from '../data/location/states';
import {
  CountryType,
  enabledCountries,
  findCountry,
} from '../data/location/countries';
import { formatDataString } from '../helpers/formatting';
import { Business, CoverageArea } from '../types/business';
import ApiError from '../types/errors/ApiError';
import { fetchFromCache, saveToCache } from './redis';
import { getMaxAgeFromResponse } from '../helpers/http';
import { LatLng } from '../types/maps';

/* istanbul ignore next */
const { serverRuntimeConfig } = getConfig() || {};

export interface StateCountry {
  state: StateType | null;
  country: CountryType | null;
}

export interface CityStateCountry extends StateCountry {
  district?: string | null;
  city: string | null;
}

export interface GeoInfo extends CityStateCountry {
  center: LatLng;
  zoom: number | null;
}

export interface GeoInfoAndPolygons {
  geoInfo: GeoInfo;
  polygons: Array<Array<LatLng>> | null;
}

const urlParamsToGeoInfo = (request: NextApiRequest): GeoInfo => {
  const params = request?.query;
  if (!params) {
    return undefined;
  }
  let center = null;
  if (params.lat && params.lng) {
    center = {
      lat: Number(params.lat.toString()),
      lng: Number(params.lng.toString()),
    };
  }
  const country = findCountry(params.country?.toString());
  const state = country
    ? findStateInCountry(country, params.state?.toString())
    : null;
  const city = params.city?.toString() || null;
  return {
    center,
    city,
    state,
    country,
    zoom: null,
  };
};

const geoInfoToUrlParams = (geoInfo: GeoInfo): string[] => {
  if (!geoInfo) {
    return [];
  }
  const params: string[] = [];
  if (geoInfo.center) {
    params.push(`lat=${geoInfo.center.lat?.toString()}`);
    params.push(`lng=${geoInfo.center.lng?.toString()}`);
  }
  if (geoInfo.city) {
    params.push(`city=${geoInfo.city}`);
  }
  if (geoInfo.state) {
    params.push(`state=${geoInfo.state.abbreviation}`);
  }
  if (geoInfo.country) {
    params.push(`country=${geoInfo.country.alpha3}`);
  }
  return params;
};

/**
 * Get the string representation of a CityStateCountry
 * @param input A CityStateCountry object
 * @param includeCountry Whether or not to include the country in the output.
 * @returns A user-readable string repersenting the input.
 */
const prettyPrintCityStateCountry = (
  input: CityStateCountry,
  includeCountry?: boolean
): string => {
  if (!input) {
    return undefined;
  }
  const output = [];
  if (input.city) {
    output.push(formatDataString(input.city));
  }
  if (input.state) {
    output.push(input.state.abbreviation);
  }
  if (input.country && includeCountry) {
    output.push(input.country.alpha3);
  }
  return output.length > 0 ? output.join(', ') : undefined;
};

const buildHereApiUrl = (
  query: string,
  country?: string,
  state?: string,
  shapes?: boolean
): string => {
  const countryQuery =
    country || enabledCountries.map((c) => c.alpha3).join(',');
  if (shapes) {
    const queryParams = {
      city: query,
      state: state,
      country: countryQuery,
      polygon_geojson: '1',
      format: 'json',
    };
    return `https://forward-reverse-geocoding.p.rapidapi.com/v1/forward?${qs.stringify(
      queryParams,
      {
        skipNull: true,
        skipEmptyString: true,
      }
    )}`;
  }

  const queryParams = {
    q: query,
    qq: state ? `state=${state}` : null,
    in: `countryCode:${countryQuery}`,
    apiKey: serverRuntimeConfig.hereKey,
  };
  if (countryQuery.toUpperCase() === 'USA' && state?.toUpperCase() === 'DC') {
    queryParams.qq = `city=${state}`;
  }
  return `${serverRuntimeConfig.hereGeocodeSearchUrl}/v1/geocode?${qs.stringify(
    queryParams,
    { skipNull: true, skipEmptyString: true }
  )}`;
};

const processOpenStreetLsResponse = (response): Array<Array<LatLng>> => {
  let location;
  for (let z = 0; z < response.length; z++) {
    // Sometimes the lookup will return multiple locations.  We only want the location of type "relation" or "polygon" since they represent cities
    /* istanbul ignore next */
    if (
      response[z].osm_type.toLowerCase() === 'relation' ||
      response[z].osm_type.toLowerCase() === 'polygon'
    ) {
      location = response[z];
      break;
    }
  }
  const type = location?.geojson?.type;
  const coordinates = location?.geojson?.coordinates;
  if (location) {
    return openStreetPolygonParser(type, coordinates);
  }
  return [];
};

const processHereSearchResponse = (response): GeoInfoAndPolygons => {
  let center = null;
  let city = null;
  let district = null;
  let state = null;
  let country = null;

  const info = response?.items[0];
  if (info?.address) {
    city = info.address.city || null;
    district = info.address.district || null;
    country = info.address.countryCode
      ? findCountry(info.address.countryCode)
      : null;
    if (country === undefined) {
      country = null;
    } else if (country && info.address.state) {
      state = findStateInCountry(country, info.address.state);
      if (state === undefined) {
        state = null;
      }
    }
  }
  if (info?.position) {
    center = {
      lat: info.position.lat,
      lng: info.position.lng,
    };
  }
  return {
    geoInfo: {
      center,
      city,
      district,
      state,
      country,
      zoom: null,
    },
    polygons: [],
  };
};

/**
 * Create a CityStateCountry object from a single input string.
 * @param locationString A string describing a location.  Expected format is city, state, country
 * @returns A CityStateCountry object describing the location.
 */
export const getLocationFromString = (
  locationString: string
): CityStateCountry => {
  let state;
  let city;
  if (!locationString) {
    return undefined;
  }
  const locationArray = locationString.split(',').map((l) => l.trim());
  const { length } = locationArray;
  if (length > 3) {
    // Should be a comma'ed city name.
    city = locationArray.slice(0, -2).join(', ');
  } else if (length === 3) {
    city = locationArray[0];
  }
  const country = findCountry(locationArray[length - 1]);
  if (country && length > 1) {
    state = findStateInCountry(country, locationArray[length - 2]);
  }
  return {
    country,
    state,
    city,
  };
};

export const getLocationFromCoverageArea = (
  area: CoverageArea
): CityStateCountry => {
  let city;
  let state;
  const country = findCountry(area.countryCode);
  if (country) {
    state = findStateInCountry(country, area.admin1);
    if (state) {
      city = area.locality;
    }
    return {
      city,
      state,
      country,
    };
  }
  return undefined;
};

export const getGeoInfoCacheKey = (
  location: string,
  state?: string,
  country?: string,
  shapes?: boolean
) => {
  return `${location ? location?.replace(/\s/g, '')?.toLowerCase() : 'null'}-${
    shapes ? 'yes' : 'no'
  }-${country || 'null'}-${state || 'null'}`;
};

/**
 * Get geographic information for a given location string.
 * @param location A string representing a location.
 * @param state Optional StateType to search within.
 * @param country Optional, the country to be searched, will only return results in the country.
 * @param shapes Specify if you want shape data or not.
 * @returns A GeoInfo object describing the first result of the searched location.
 */
const getGeoInfo = async (
  location: string,
  state?: string,
  country?: string,
  shapes?: boolean,
  bypassCacheLookup?: boolean
): Promise<GeoInfoAndPolygons | Array<Array<LatLng>>> => {
  // Default these to null instead of undefined so that they can be serialized via getServerSideProps
  const emptyResult = Promise.resolve({
    geoInfo: {
      addresses: [],
      center: null,
      city: null,
      state: null,
      country: null,
      zoom: null,
    },
    polygons: [],
  });

  const cacheKey = getGeoInfoCacheKey(location, state, country, shapes);

  let result;
  if (!bypassCacheLookup) {
    try {
      result = await fetchFromCache(cacheKey);
    } catch (e) {
      Bugsnag.notify(new Error(`ERROR: Cannot get geoInfo from cache - ${e}`));
    }
  }

  if (!result) {
    const url = buildHereApiUrl(location, country, state, shapes);
    /* istanbul ignore next */
    if (!url) {
      Bugsnag.notify(new Error(`ERROR: Cannot build URL for ${location}`));
      return emptyResult;
    }
    let geocodeResponse;
    if (shapes) {
      geocodeResponse = await fetch(url, {
        headers: {
          'x-rapidapi-host': 'forward-reverse-geocoding.p.rapidapi.com',
          'x-rapidapi-key': serverRuntimeConfig.rapidApiKey,
        },
      });
    } else {
      geocodeResponse = await fetch(url);
    }
    const { status } = geocodeResponse;
    const text = geocodeResponse.statusText;
    if (status >= 400) {
      Bugsnag.notify(new Error(`ERROR: ${status}: "${text}" fetching ${url}`));
      throw new ApiError('Error fetching HERE API results.');
    }

    result = await geocodeResponse?.json();
    try {
      await saveToCache(
        cacheKey,
        result,
        getMaxAgeFromResponse(geocodeResponse)
      );
    } catch (e) {
      Bugsnag.notify(new Error(`ERROR: Cannot save geoInfo to cache - ${e}`));
    }
  }
  if (shapes) {
    return processOpenStreetLsResponse(result);
  }
  return processHereSearchResponse(result);
};

const getGeoInfoFromBusiness = (business: Business): GeoInfo => {
  const address =
    business?.primaryAddress || business?.addresses[0] || undefined;
  const country = findCountry(address?.countryCode as string);
  const state = findStateInCountry(country, address?.province);
  return {
    center:
      business?.latLng?.lat && business?.latLng?.lng
        ? business?.latLng
        : undefined,
    city: address?.city,
    state,
    country,
    zoom: matchLevelZoomReferences.city,
  };
};

export {
  getGeoInfo,
  prettyPrintCityStateCountry,
  urlParamsToGeoInfo,
  geoInfoToUrlParams,
  getGeoInfoFromBusiness,
  processOpenStreetLsResponse,
  processHereSearchResponse,
  buildHereApiUrl,
};
