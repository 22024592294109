import React, { forwardRef, memo, useCallback, MouseEvent } from 'react';
import { sendAnalyticsEvent, Provider } from './utilities';

type AnalyticsWrappedComponentType = React.HTMLAttributes<any> & {
  children?: React.ReactNode;
  eventLabel: string;
  componentType: React.ComponentType | keyof JSX.IntrinsicElements;
  eventText: string;
  onClick?: () => any;
  [key: string]: any;
  providers?: { [key in Provider]: boolean };
};

const defaultOnClick = () => Promise.resolve();

const AnalyticsWrappedComponent = forwardRef<
  any,
  AnalyticsWrappedComponentType
>(function AnalyticsWrappedComponent(
  {
    children,
    eventLabel,
    eventText,
    componentType: ComponentType,
    onClick = defaultOnClick,
    additionalEventProperties,
    providers = { Amplitude: true },
    ...rest
  },
  ref
) {
  const handleClicked = useCallback(
    async (event: MouseEvent) => {
      sendAnalyticsEvent(
        eventText,
        eventLabel,
        'Click',
        additionalEventProperties,
        providers
      );
      await onClick(event);
    },
    [eventLabel, eventText, onClick, additionalEventProperties, providers]
  );

  return (
    <ComponentType
      // @ts-ignore
      ref={ref}
      onClick={handleClicked}
      {...rest}
    >
      {children}
    </ComponentType>
  );
});

AnalyticsWrappedComponent.displayName = 'AnalyticsWrappedComponent';

export default memo(AnalyticsWrappedComponent);
