import Bugsnag from '@bugsnag/js';
import { getRedisConnection, CACHE_EXPIRATION_HOURS } from '../lib/redis/redis';

export async function fetchFromCache(key: string) {
  try {
    const redis = getRedisConnection();
    const data = await redis.get(key);
    if (data) {
      return JSON.parse(data);
    }
    return undefined;
  } catch (e) {
    Bugsnag.notify(e);
    throw e;
  }
}

export async function fetchFromCacheBulk(keys: string[]) {
  try {
    const redis = getRedisConnection();
    const data = await redis.mget(keys);
    return data.map((d) => JSON.parse(d));
  } catch (e) {
    Bugsnag.notify(e);
    throw e;
  }
}

export async function saveToCache(
  key: string,
  data: any,
  expiration?: number | false
): Promise<string | undefined> {
  try {
    const redis = getRedisConnection();
    if (expiration !== false) {
      return redis.set(
        key,
        JSON.stringify(data),
        'EX',
        expiration || 60 * 60 * CACHE_EXPIRATION_HOURS
      );
    }
    return redis.set(key, JSON.stringify(data));
  } catch (e) {
    Bugsnag.notify(e);
    throw e;
  }
}

// mset does not support expirations
export async function saveToCacheBulk(
  data: Array<{ key: string; value: any }>
): Promise<string | undefined> {
  try {
    const redis = getRedisConnection();
    return redis.mset(data.flatMap((d) => [d.key, JSON.stringify(d.value)]));
  } catch (e) {
    Bugsnag.notify(e);
    throw e;
  }
}

export async function deleteFromCache(key: string): Promise<number> {
  try {
    const redis = getRedisConnection();
    return redis.del(key);
  } catch (e) {
    Bugsnag.notify(e);
    throw e;
  }
}

export async function getCacheCount() {
  const redis = getRedisConnection();
  return redis.dbsize();
}
