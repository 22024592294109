import wicket from 'wicket';

const matchLevelZoomReferences = {
  country: 3,
  state: 6,
  county: 9,
  city: 10,
  district: 11,
  street: 14,
  intersection: 14,
  houseNumber: 14,
  postalCode: 10,
  landmark: 14,
};

const herePolygonParser = (outerPolygon) => {
  const wkt = new wicket.Wkt();
  const parsePolygonData = (polygon) => {
    wkt.read(polygon);
    wkt.toJson();
    const polygons = wkt.components;
    for (let i = 0; i < polygons.length; i++) {
      for (let x = 0; x < polygons[i].length; x++) {
        if (wkt.type === 'multipolygon') {
          for (let y = 0; y < polygons[i][x].length; y++) {
            polygons[i][x][y].lat = polygons[i][x][y].y;
            delete polygons[i][x][y].y;
            polygons[i][x][y].lng = polygons[i][x][y].x;
            delete polygons[i][x][y].x;
          }
        } else {
          polygons[i][x].lat = polygons[i][x].y;
          delete polygons[i][x].y;
          polygons[i][x].lng = polygons[i][x].x;
          delete polygons[i][x].x;
        }
      }
      if (wkt.type === 'multipolygon') {
        polygons[i] = polygons[i][0];
      }
    }
    return polygons;
  };
  return parsePolygonData(outerPolygon);
};

const openStreetPolygonParser = (type, polygon) => {
  const parsedPolygon = [];
  for (let i = 0; i < polygon.length; i++) {
    parsedPolygon[i] = [];
    for (let x = 0; x < polygon[i].length; x++) {
      if (type === 'Polygon') {
        parsedPolygon[i][x] = {
          lat: polygon[i][x][1],
          lng: polygon[i][x][0],
        };
      } else if (type === 'MultiPolygon') {
        for (let y = 0; y < polygon[i][x].length; y++) {
          parsedPolygon[i].push({
            lat: polygon[i][x][y][1],
            lng: polygon[i][x][y][0],
          });
        }
      }
    }
  }
  return parsedPolygon;
};

export { matchLevelZoomReferences, herePolygonParser, openStreetPolygonParser };
