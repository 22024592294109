import Bugsnag from '@bugsnag/js';
import { CityStateCountry } from '../../services/geo-info';
import { buildAutocompleteUrl } from '../../helpers/urls';
import { findCountry } from './countries';
import { findStateInCountry } from './states';
import { fetchWithRetry } from '../../helpers/retrier';

export interface HereAddress {
  label: string;
  countryCode: string;
  countryName: string;
  stateCode: string;
  state: string;
  county: string;
  city: string;
  postalCode: string;
}

export function isHereAddress(object: any): object is HereAddress {
  return (
    (object as HereAddress).countryCode !== undefined &&
    (object as HereAddress).label !== undefined &&
    (object as HereAddress).countryName !== undefined
  );
}

export interface HereAutocompleteItem {
  title: string;
  id: string;
  resultType: string;
  localityType?: string;
  address: HereAddress;
  administrativeAreaType?: string;
  // highlights?: any; // Define this if we ever want to use it.
}

export interface HereAutoCompleteFormatOptions {
  city?: boolean;
  state?: boolean;
  country?: boolean;
  zip?: boolean;
}

const formatAutoCompleteAddress = (
  address: HereAddress,
  options: HereAutoCompleteFormatOptions = {
    city: true,
    state: true,
    country: true,
    zip: true,
  }
): string => {
  const resultList = [];

  if (options.city && address?.city) {
    resultList.push(address.city);
  }
  if (options.state && address?.stateCode) {
    resultList.push(address.stateCode);
  }
  if (options.country && address?.countryCode) {
    resultList.push(address.countryCode);
  }
  if (options.zip && address?.postalCode) {
    resultList.push(address.postalCode);
  }
  return resultList.join(', ');
};

const getCityStateCountry = (input: HereAutocompleteItem): CityStateCountry => {
  if (!input) {
    return {
      country: undefined,
      state: undefined,
      city: undefined,
    };
  }
  let state;
  let city;
  const country = findCountry(input?.address?.countryCode);
  if (country) {
    state = findStateInCountry(country, input?.address?.stateCode);
    if (state) {
      city = input?.address?.city;
    }
  }
  return {
    country,
    state,
    city,
  };
};

const fetchAutocomplete = async (
  query: string,
  filterDuplicates?: boolean // We may want to stop doing this and change how we render the items to show the difference between the dupes.
): Promise<HereAutocompleteItem[]> => {
  const result = await fetchWithRetry(
    `fetchAutocomplete(${query})`,
    buildAutocompleteUrl(query)
  ).catch((e) => {
    Bugsnag.notify(e);
    return Promise.resolve({ json: () => null });
  });
  const resultJson: HereAutocompleteItem[] = await result.json();
  if (resultJson) {
    if (!filterDuplicates) {
      return resultJson;
    }
    return resultJson.filter(
      (item, index, self) =>
        index ===
        self.findIndex(
          (a) =>
            a.resultType === item.resultType &&
            a.address.countryCode === item.address.countryCode &&
            a.address.stateCode === item.address.stateCode &&
            a.address.city === item.address.city
        )
    );
  }
  return [];
};

export { fetchAutocomplete, formatAutoCompleteAddress, getCityStateCountry };
