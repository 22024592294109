import { CountryType } from './countries';

export type StateType = {
  name: string;
  abbreviation: string;
  sortOrder: number;
};

const statesMap = {
  US: [
    { name: 'Alabama', abbreviation: 'AL', sortOrder: 32 },
    { name: 'Alaska', abbreviation: 'AK', sortOrder: 49 },
    { name: 'Arizona', abbreviation: 'AZ', sortOrder: 20 },
    { name: 'Arkansas', abbreviation: 'AR', sortOrder: 33 },
    { name: 'California', abbreviation: 'CA', sortOrder: 2 },
    { name: 'Colorado', abbreviation: 'CO', sortOrder: 6 },
    { name: 'Connecticut', abbreviation: 'CT', sortOrder: 23 },
    { name: 'Delaware', abbreviation: 'DE', sortOrder: 42 },
    { name: 'Florida', abbreviation: 'FL', sortOrder: 1 },
    { name: 'Georgia', abbreviation: 'GA', sortOrder: 8 },
    { name: 'Hawaii', abbreviation: 'HI', sortOrder: 48 },
    { name: 'Idaho', abbreviation: 'ID', sortOrder: 39 },
    { name: 'Illinois', abbreviation: 'IL', sortOrder: 5 },
    { name: 'Indiana', abbreviation: 'IN', sortOrder: 16 },
    { name: 'Iowa', abbreviation: 'IA', sortOrder: 27 },
    { name: 'Kansas', abbreviation: 'KS', sortOrder: 22 },
    { name: 'Kentucky', abbreviation: 'KY', sortOrder: 28 },
    { name: 'Louisiana', abbreviation: 'LA', sortOrder: 26 },
    { name: 'Maine', abbreviation: 'ME', sortOrder: 37 },
    { name: 'Maryland', abbreviation: 'MD', sortOrder: 15 },
    { name: 'Massachusetts', abbreviation: 'MA', sortOrder: 9 },
    { name: 'Michigan', abbreviation: 'MI', sortOrder: 12 },
    { name: 'Minnesota', abbreviation: 'MN', sortOrder: 13 },
    { name: 'Mississippi', abbreviation: 'MS', sortOrder: 40 },
    { name: 'Missouri', abbreviation: 'MO', sortOrder: 17 },
    { name: 'Montana', abbreviation: 'MT', sortOrder: 44 },
    { name: 'Nebraska', abbreviation: 'NE', sortOrder: 36 },
    { name: 'Nevada', abbreviation: 'NV', sortOrder: 34 },
    { name: 'New Hampshire', abbreviation: 'NH', sortOrder: 35 },
    { name: 'New Jersey', abbreviation: 'NJ', sortOrder: 25 },
    { name: 'New Mexico', abbreviation: 'NM', sortOrder: 38 },
    { name: 'New York', abbreviation: 'NY', sortOrder: 4 },
    { name: 'North Carolina', abbreviation: 'NC', sortOrder: 10 },
    { name: 'North Dakota', abbreviation: 'ND', sortOrder: 47 },
    { name: 'Ohio', abbreviation: 'OH', sortOrder: 11 },
    { name: 'Oklahoma', abbreviation: 'OK', sortOrder: 31 },
    { name: 'Oregon', abbreviation: 'OR', sortOrder: 30 },
    { name: 'Pennsylvania', abbreviation: 'PA', sortOrder: 7 },
    { name: 'Rhode Island', abbreviation: 'RI', sortOrder: 46 },
    { name: 'South Carolina', abbreviation: 'SC', sortOrder: 24 },
    { name: 'South Dakota', abbreviation: 'SD', sortOrder: 43 },
    { name: 'Tennessee', abbreviation: 'TN', sortOrder: 19 },
    { name: 'Texas', abbreviation: 'TX', sortOrder: 3 },
    { name: 'Utah', abbreviation: 'UT', sortOrder: 29 },
    { name: 'Vermont', abbreviation: 'VT', sortOrder: 41 },
    { name: 'Virginia', abbreviation: 'VA', sortOrder: 14 },
    { name: 'Washington', abbreviation: 'WA', sortOrder: 21 },
    { name: 'District of Columbia', abbreviation: 'DC', sortOrder: 51 },
    { name: 'West Virginia', abbreviation: 'WV', sortOrder: 45 },
    { name: 'Wisconsin', abbreviation: 'WI', sortOrder: 18 },
    { name: 'Wyoming', abbreviation: 'WY', sortOrder: 50 },
  ],
  CA: [
    { name: 'Alberta', abbreviation: 'AB', sortOrder: 1 },
    { name: 'British Columbia', abbreviation: 'BC', sortOrder: 2 },
    { name: 'Manitoba', abbreviation: 'MB', sortOrder: 3 },
    { name: 'New Brunswick', abbreviation: 'NB', sortOrder: 4 },
    { name: 'Newfoundland and Labrador', abbreviation: 'NL', sortOrder: 5 },
    { name: 'Northwest Territories', abbreviation: 'NT', sortOrder: 6 },
    { name: 'Nova Scotia', abbreviation: 'NS', sortOrder: 7 },
    { name: 'Nunavut', abbreviation: 'NU', sortOrder: 8 },
    { name: 'Ontario', abbreviation: 'ON', sortOrder: 9 },
    { name: 'Prince Edward Island', abbreviation: 'PE', sortOrder: 10 },
    { name: 'Québec', abbreviation: 'QC', sortOrder: 11 },
    { name: 'Saskatchewan', abbreviation: 'SK', sortOrder: 12 },
  ],
};

export const statesAbbreviationList = statesMap.US.map((s) => s.abbreviation);
export const provincesAbbreviationList = statesMap.CA.map(
  (s) => s.abbreviation
);

/**
 * Find the state object within a given country by a given name or abbreviation.
 * @param country A CountryType object that we are looking within.
 * @param input Name or two-letter abbreviation of the state, not case-sensitive.
 * @returns A StateType object of the found state, undefined if not found.
 */
export const findStateInCountry = (
  country: CountryType,
  input: string
): StateType => {
  if (!country || !input) {
    return undefined;
  }
  const newInput = input.trim().toUpperCase();
  const states = statesMap[country.alpha2];
  if (!states?.length) {
    return undefined;
  }
  return states.find(
    (state) =>
      state.abbreviation === newInput || state.name.toUpperCase() === newInput
  );
};

export const getStateList = (country: CountryType): StateType[] => {
  return [...(statesMap[country.alpha2] || [])];
};
