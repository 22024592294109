import { logFetch } from '../shared/log';

const baseWithRetry = async (retries, fnCall, loggingContext, timeout) => {
  try {
    return await fnCall();
  } catch (error) {
    const retriesRemaining = retries - 1;
    if (retriesRemaining > 0) {
      await new Promise((resolve) => {
        setTimeout(resolve, timeout);
      });
      logFetch(
        `Retrying failed ${loggingContext} (${retriesRemaining} remaining): ${error}`
      );
      return baseWithRetry(retriesRemaining, fnCall, loggingContext, timeout);
    }
    logFetch(`Failed ${loggingContext}: ${error}`);
    throw error;
  }
};

export async function fetchWithRetry(
  loggingContext,
  url,
  options = {},
  timeout = 500
) {
  return baseWithRetry(
    3,
    async () => {
      return fetch(url, options);
    },
    loggingContext,
    timeout
  );
}

export async function requestWithRetry(loggingContext, fnCall, timeout = 500) {
  return baseWithRetry(3, fnCall, loggingContext, timeout);
}
