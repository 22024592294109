import console from '../globalWrappers/console';

const logES = (...str) => {
  if (process.env.DEBUG_ES === 'true') {
    console.log(...str);
  }
};

const logRedis = (...str) => {
  if (process.env.DEBUG_REDIS === 'true') {
    console.log(str);
  }
};

const logFetch = (...str) => {
  if (process.env.DEBUG_FETCH === 'true') {
    console.log(str);
  }
};

export { logES, logRedis, logFetch };
