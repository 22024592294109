const formatUrl = (url: string): string =>
  url.replace(/^https:\/\//, '').replace(/^http:\/\//, '');

/**
 * @param input Input string to format
 * @returns String with underscores converted to spaces and the first letter of each word capitalized (with the exception of "and")
 */
const formatDataString = (input: string): string => {
  if (!input) {
    return input;
  }
  return input
    .replace(/_/g, ' ')
    .replace(
      /(^\w|\s\w)(\S*)/g,
      (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
    )
    .replace(/\band\b/gi, 'and');
};

/**
 *
 * @param input Input string to format
 * @returns String with & converted to 'and', spaces converted to underscores, and all lowercase
 */
const formatSearchString = (input: string, spaceCharacter?: string): string => {
  if (!input) {
    return input;
  }
  return input
    .split(/\s+/) // Split on any whitespace
    .map((s) => s.replace(/&/, 'and').toLowerCase())
    .join(spaceCharacter || '_');
};

const formatZipCode = (zip: string): string => {
  if (zip.length > 7) {
    return zip.substring(0, 7);
  }
  return zip;
};

export { formatUrl, formatDataString, formatSearchString, formatZipCode };
