const Bugsnag = require('@bugsnag/js');
const IoRedis = require('ioredis');
const { logRedis } = require('../../shared/log');

const CACHE_EXPIRATION_HOURS = Number(process.env.REDIS_EXPIRATION_HOURS) || 24;
const REDIS_CONNECTION_RETRIES =
  parseInt(process.env.REDIS_CONNECTION_RETRIES, 10) || 3;

const REDIS_ENDPOINT = process.env.SECRET_REDIS_ENDPOINT || '127.0.0.1';
const redisConfig = {
  port: 6379,
  host: REDIS_ENDPOINT,
  password: process.env.SECRET_REDIS_PASSWORD,
  autoResubscribe: false,
  lazyConnect: true,
  retryStrategy(times) {
    if (times >= REDIS_CONNECTION_RETRIES) {
      // End reconnecting with built in error
      return undefined;
    }
    // reconnect after
    return Math.min(times * 1000, 3000); // To prevent long wait if retries is high.
  },
};

if (process.env.SECRET_REDIS_ENDPOINT) {
  // See https://github.com/luin/ioredis/issues/894
  redisConfig.tls = {};
}
const Redis = IoRedis.default;
const redis = new Redis(redisConfig);
redis.on('error', (e) => {
  Bugsnag.notify(e);
});

redis.on('connect', () => {
  logRedis(`Connected to Redis: ${REDIS_ENDPOINT}`);
});

function getRedisConnection() {
  return redis;
}

exports.CACHE_EXPIRATION_HOURS = CACHE_EXPIRATION_HOURS;
exports.REDIS_CONNECTION_RETRIES = REDIS_CONNECTION_RETRIES;
exports.REDIS_ENDPOINT = REDIS_ENDPOINT;
exports.getRedisConnection = getRedisConnection;
