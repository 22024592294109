export interface CountryType {
  name: string;
  alternateNames?: string[];
  preferredName?: string;
  alpha2: string;
  alpha3: string;
  enabled: boolean;
}

/*
 * List of countries supported by the PCN.
 */
export const countries: CountryType[] = [
  {
    name: 'United States of America',
    alternateNames: ['United States'],
    preferredName: 'United States',
    alpha2: 'US',
    alpha3: 'USA',
    enabled: true,
  },
  {
    name: 'Canada',
    alternateNames: ['Canada'],
    preferredName: 'Canada',
    alpha2: 'CA',
    alpha3: 'CAN',
    enabled: true,
  },
];

/**
 * Give the country object that is represented by the given input.
 * @param input String to search by, can be name or an ISO-3166 country abbreviation, case does not matter.  Can be alpha-2 or alpha-3.
 * @returns A CountryType object for the given country, or undefined if the country is not found.
 */
export const findCountry = (input: string): CountryType => {
  if (!input) {
    return undefined;
  }
  const newInput = input.trim().toUpperCase();
  return countries.find(
    (country) =>
      country.alpha2 === newInput ||
      country.alpha3 === newInput ||
      country.name.toUpperCase() === newInput ||
      country?.alternateNames?.map((n) => n.toUpperCase()).includes(newInput)
  );
};

/**
 * Check if a string is a valid country identifier.
 * @param input A string to be checked.
 * @returns Whether or not the string represents a country that PCN recognizes.
 */
export const isACountry = (input: string): boolean =>
  findCountry(input) !== undefined;

export const enabledCountries = countries.filter((c) => c.enabled);

export const DEFAULT_COUNTRY = countries[0];
export const CANADA = countries[1];
