import { SeoListType } from '../../types/seo-list-type';

interface ProvidedServiceType extends SeoListType {
  shortName?: string;
  id?: {
    staging: string;
    prod: string;
  };
}

// Note: The ID's here are a temp solution and some may be missing on staging and/or prod.
const providedServices = {
  design_and_engineering: {
    name: 'Design and Engineering',
    icon: '/provided-services/design.jpg',
    urlKey: 'design-and-engineering',
    description:
      'Engineering professionals have careers that vary vastly in scope and impact. However, one constant element is their responsibility to create structures that endure. This can mean designing structures to last, performing belt-tightening exercises to keep them strong, or even preventing collapses that might cause personal injury or damage to property. Regardless of where your career lies in the overall scope of E and E activity, it is critical to understand the nature of risk involved when performing design work within the Construction Sector. Safety is of paramount importance whether this is your day job or profession. Design and Engineering provides services within construction and related disciplines to help civil engineers during all stages of their career.',
    sortOrder: 1,
    id: {
      staging: '13460f81-5bd4-4826-9103-e63d527360d6',
      prod: '39b14b06-eebe-43b7-ba51-8dfc1da85337',
    },
    synonyms: [
      'Planning & Design',
      'Architecture',
      'Drafting',
      'Blueprints',
      'Design and Engineering',
      'Planning and Design',
      'Desing & Engeneering',
      'Desgin & Engineering',
      'Desing and Engeneering',
      'Desgin and Engineering',
      'CAD',
      'Computer-Aided Design',
      'Engineering Design',
      'Architectural Design',
      'Drafting and Design',
      'Design Services',
      'Design and Engineering Firms',
      'Design and Drafting',
      'Design and Build',
    ],
  },
  project_management_and_coordination: {
    name: 'Project Management',
    icon: '/provided-services/project-management.jpg',
    urlKey: 'project-management-and-coordination',
    description:
      'Due to the ever-increasing complexity of construction projects, project managers and coordination personnel need to have a wide assortment of skills and certifications. As a tradesperson in this field, youll need to demonstrate your expertise through participation in both formal construction training programs as well as informal job-site training.',
    sortOrder: 2,
    id: {
      staging: 'f7e363ff-f59a-42c1-b4af-16ed542e7351',
      prod: '41d85470-7725-47b3-8dc2-c2d12ecfaa5c',
    },
    synonyms: [
      'Project Planning',
      'Project Coordination',
      'Project Control',
      'Project Supervision',
      'Project Managment & Coordination',
      'Proejct Management & Coordination',
      'Project Managment and Coordination',
      'Proejct Management and Coordination',
      'Project Leadership',
      'Project Administration',
      'Project Organization',
      'Project Oversight',
      'Project Coordination Services',
      'Project Management Firms',
      'Construction Management',
      'Construction Coordination',
    ],
  },
  demolition: {
    name: 'Demolition',
    icon: '/provided-services/demolition.jpg',
    urlKey: 'demolition',
    description:
      'The job of a demolition contractor varies depending on the size and complexity of your project, but the work they do is important. You want to make sure contractors do their jobs under safe and efficient conditions so you avoid paying more in the long run. You want to know that contractors are being honest with you regarding costs. And, you want to be sure they’re providing quality work—from the materials they use to the people they hire and the workflow they follow.',
    sortOrder: 3,
    id: {
      staging: '1ce0eefe-304a-4443-9e11-3a882bf088fb',
      prod: '54d8ecc1-69e0-4099-b3c1-a9098749e033',
    },
    synonyms: [
      'Destruction',
      'Wrecking',
      'Razing',
      'Dismantling',
      'Demolision',
      'Demloition',
      'Building Demolition',
      'Demolition Services',
      'Demolition Contractors',
      'Demolition Crews',
      'Demolition and Site Clearance',
      'Demolition and Deconstruction',
      'Building Implosion',
    ],
  },
  earthwork: {
    name: 'Earthwork',
    icon: '/provided-services/earthwork.jpg',
    urlKey: 'earthwork',
    description:
      'Not all forms of landscape architecture are accepted by the general public. While some forms are more commonly associated with popular architectural themes, Earthworks are works created that increase awareness of urban environments and their contributions to public health through practices such as earthmoving, hillside preservation and construction of retaining walls and bridges over streams and aqueducts. Earthworks are not necessarily monumental in scale and frequently incorporate elements of natural landscape such as retaining walls and bridges.',
    sortOrder: 4,
    id: {
      staging: '464ad035-67c4-4690-9822-abaf96a3e299',
      prod: '69adc6c7-d79d-4e29-bdab-7df8c2b9c5ab',
    },
    synonyms: [
      'Excavation',
      'Grading',
      'Land Clearing',
      'Site Preparation',
      'Earthwrok',
      'Earhtwork',
      'Earthmoving',
      'Earthmoving Services',
      'Land Grading',
      'Site Development',
      'Site Preparation Contractors',
      'Excavation Services',
      'Excavating Companies',
      'Earthworks and Landscaping',
    ],
  },
  landscaping: {
    name: 'Landscaping',
    icon: '/provided-services/landscaping.jpg',
    urlKey: 'landscaping',
    description:
      'Landscaping is all about creating a beautiful environment for your visitors. This may involve working with hardwood trees that are fastidiously cared for or carefully planted shrubby plants in fields to hold water and protect crops. That’s why it is vital to have the right landscape architect on your team who can provide design solutions for all sorts of situations. Whatever type of landscaping project you are involved in, having the right landscape contractor on board can increase efficiency and profitability significantly.',
    sortOrder: 5,
    id: {
      staging: 'd20b54c1-0364-40d1-9083-44177cd33b78',
      prod: '41eda83f-1cd5-4684-baa2-5ab42a274971',
    },
    synonyms: [
      'Gardening',
      'Groundskeeping',
      'Horticulture',
      'Landscaping Design',
      'Landcaping',
      'Lanscaping',
      'Landscape Design',
      'Landscape Maintenance',
      'Lawn Care',
      'Gardening Services',
      'Garden Design',
      'Garden Landscaping',
      'Commercial Landscaping',
      'Residential Landscaping',
    ],
  },
  concrete: {
    name: 'Concrete',
    icon: '/provided-services/concrete.jpg',
    urlKey: 'concrete',
    description:
      'Concrete is most frequently used as a highly cohesive, waterproofing material. It is used extensively in buildings, roads, and industrial equipment. Today, concrete has achieved widespread acceptance as a primary building material due to its versatility, strength, light weight, and recyclability. It is considered one of the safer materials used today, with some products considered more resistant to natural disasters, physical attacks, fires, explosions, melting temperatures (if poured into a furnace or set on fire), and flooding.',
    sortOrder: 6,
    id: {
      staging: '05295f48-a83b-4efd-b15c-1f66a0459a90',
      prod: '64a1fb01-f48a-4e43-84a3-cbd15c289882',
    },
    synonyms: [
      'Cement',
      'Paving',
      'Flooring',
      'Concreete',
      'Concreate',
      'Concrete Contractors',
      'Concrete Services',
      'Concrete Repair',
      'Concrete Finishing',
      'Concrete Foundations',
      'Concrete Pumping',
      'Concrete Cutting',
      'Concrete Polishing',
    ],
  },
  masonry: {
    name: 'Masonry',
    icon: '/provided-services/masonry.jpg',
    urlKey: 'masonry',
    description:
      'In many ways masonry is simpler than other construction methods. It is also more efficient, as each unit takes up little space. It is generally considered a safer method of building than other methods because all of the debris and potential issues (such as fires and explosions) can be contained within the unit itself instead of spreading outside of it. Additionally, since masonry is made from units of smaller components (timbers and boards) there is less chance for damage in the event of an earthquake or hurricane.',
    sortOrder: 7,
    id: {
      staging: '87db5523-3329-4400-9b52-df0fd73cdc41',
      prod: '516f2877-c133-498f-bbde-ebb75d88d7f2',
    },
    synonyms: [
      'Brickwork',
      'Stonework',
      'Masonry Construction',
      'Concrete Masonry',
      'Msonary',
      'Masnory',
      'Masonry Services',
      'Masonry Repair',
      'Stone Masonry',
      'Brick Masonry',
      'Brickwork Contractors',
      'Stone Cladding',
      'Stonemasons',
      'Masonry Restoration',
    ],
  },
  structural_steel: {
    name: 'Structural Steel',
    icon: '/provided-services/structural-steel.jpg',
    urlKey: 'structural-steel',
    description:
      'Structural steel is ideal for both light and heavy construction. It performs as a heavy structural steel but it reduces the overall size of the piece. This makes it useful for structures such as bridges, towers, and warehouses. Structural steel is typically used in commercial and residential construction projects where strength and stiffness is required.',
    sortOrder: 8,
    id: {
      staging: 'b016cdd5-2771-4961-acef-14363c6aa01b',
      prod: '8b8108fc-39ac-4275-aa0e-c1b85e754b53',
    },
    synonyms: [
      'Steel Fabrication',
      'Steel Erection',
      'Metal Fabrication',
      'Structural Ironwork',
      'Structural Stell',
      'Strucutral Steel',
      'Steel Construction',
      'Steel Buildings',
      'Steel Fabricators',
      'Structural Steel Fabrication',
      'Steel Erectors',
      'Metal Works',
      'Steel Framing',
      'Steel Reinforcement',
    ],
  },
  rough_carpentry: {
    name: 'Rough Carpentry',
    icon: null,
    urlKey: 'rough-carpentry',
    description:
      'In rough carpentry there is usually more than one alignment to achieve an aesthetic look, although this term is often used to refer to the process of fitting together timber or other material as opposed to the finished product. It involves using a range of saws and other machinery to cut the boards from logs, sometimes in a straight line and sometimes at an angle. The application of sawdust and skinsab mounted on top of the boards is what gives rough carpentry its unique look.',
    sortOrder: 9,
    id: {
      staging: '83cadb91-6957-412a-9ac5-e3d7029484ad',
      prod: '5f3d8d24-a665-4a3a-a29f-daf0658d8374',
    },
    synonyms: [
      'Framing',
      'Rough Construction',
      'Rough Woodwork',
      'Formwork',
      'Rough Carpenrty',
      'Rough Carpentary',
      'Carpentry Services',
      'Carpentry Contractors',
      'Rough Framing',
      'Woodworking',
      'Formwork Contractors',
      'Structural Formwork',
      'Rough Construction Services',
      'Timber Framing',
    ],
  },
  roofing: {
    name: 'Roofing',
    icon: null,
    urlKey: 'roofing',
    description:
      'Roofing contractors can make your home more comfortable, increase its value and save you money over the life of your loan. The industry is growing rapidly in the United States, especially among renters and home owners who are concerned about mold and insects. The good news is that you can find reliable, affordable roofing contractors in most states. The bad news is that even if you find a good roofer, you may not know what to expect from your experience with him or her.',
    sortOrder: 10,
    id: {
      staging: '7af93027-65e7-4ee2-ad59-3c457d094324',
      prod: 'd1995a4d-7600-4044-b50a-078d52db3d10',
    },
    synonyms: [
      'Roof Installation',
      'Roof Repair',
      'Roof Maintenance',
      'Roof Replacement',
      'Roofer',
      'Roofinng',
      'Roffing',
      'Roofing Services',
      'Roofing Contractors',
      'Roof Restoration',
      'Flat Roofs',
      'Roofing Materials',
      'Metal Roofing',
      'Roof Cleaning',
      'Roof Waterproofing',
    ],
  },
  fire_suppression: {
    name: 'Fire Suppression',
    icon: null,
    urlKey: 'fire-suppression',
    description:
      'Suppression systems can be made to fireproof, frostproof, electrify, radiosafe materials within the structure, or practically anything else that would help to stop a fire from spreading or starting a new one. Regardless of how they are designed, fire suppression systems work by sending information via radio waves or other means to stop the spread of fire. When used properly, construction materials and equipment will remain safe during an incident regardless of what type of materials or equipment.',
    sortOrder: 15,
    id: {
      staging: '62fed36d-620b-4c51-910a-a8d6b07280d7',
      prod: '11dd6612-9379-4680-b650-654fe51c18ef',
    },
    synonyms: [
      'Fire Protection',
      'Fire Prevention',
      'Fire Safety',
      'Fire Control',
      'Fire Supression',
      'Fire Suppresion',
      'Fire Extinguishers',
      'Fire Alarms',
      'Sprinkler Systems',
      'Fireproofing',
      'Fire Protection Services',
      'Fire Safety Inspections',
      'Fire Control Systems',
      'Fire Prevention Measures',
    ],
  },
  plumbing: {
    name: 'Plumbing',
    icon: null,
    urlKey: 'plumbing',
    description:
      'The plumbing industry is an integral part of any metropolitan area’s infrastructure. It provides natural gas distribution, water distribution, sanitation service and lighting as well as power-washing facilities for hotels and motels. Most homes have at least one faucet or toilet that are controlled by a human standing inside the house. Most homes have two or three pipes that carry cold or hot water. The plumbing are interconnected with each other through the use of pipes, valves, conduits, hoses and other objects.',
    sortOrder: 17,
    id: {
      staging: '9e0ad3a1-e232-42ce-9f15-05184ef27865',
      prod: 'b67e5266-625c-4d57-a898-d3e9c854028e',
    },
    synonyms: [
      'Plumbing Installation',
      'Plumbing Repair',
      'Plumbing Maintenance',
      'Plumbing Services',
      'Plumber',
      'Plumbers',
      'Plumbinng',
      'Plubming',
      'Plumbing Contractors',
      'Plumbing Fixtures',
      'Drain Cleaning',
      'Piping Systems',
      'Plumbing Repair Services',
      'Plumbing Inspection',
      'Emergency Plumbing',
      'Commercial Plumbing',
    ],
  },
  heating_ventilating_and_air_conditioning_hvac: {
    name: 'Heating Ventilation and Air Conditioning (HVAC)',
    shortName: 'HVAC',
    icon: null,
    urlKey: 'hvac',
    description:
      'In essence, an HVAC system is comprised of several elements: Heat Pump, Air Conditioning Tank, and Heating element (if installed in part). Each piece of equipment is designed to transport and deliver heat and cold via energy to surrounding spaces or items within them. In some cases, the heater may be installed directly onto a wall or ceiling, while others have accessory tanks or pipes that transport the hot air longer distances than others.',
    sortOrder: 18,
    id: {
      staging: '792f21b7-595e-4e6d-8dc4-3ce456eac362',
      prod: '62996e2c-c0c3-44f3-a7d7-f06cc33b23d1',
    },
    synonyms: [
      'Heating',
      'Ventilation',
      'Air Conditioning',
      'Climate Control',
      'HVCA',
      'HCVA',
      'HVAC Services',
      'HVAC Contractors',
      'HVAC Repair',
      'Air Quality',
      'Heating and Cooling',
      'Air Duct Cleaning',
      'Air Conditioning Installation',
      'Climate Control Systems',
    ],
  },
  electrical: {
    name: 'Electrical',
    icon: '/provided-services/electrical.jpg',
    urlKey: 'electrical',
    description:
      'A qualified and licensed electrician plays an important role on the job. He or she should have the proper training to work with dangerous tools and equipment, know how to prevent fires, provide adequate lighting for work areas, and measure electrical currents and current-related equipment readings in order to properly alert clients and workers when necessary. In addition to having a high level of technical knowledge, an electrician should have a thorough understanding of both state and federal laws pertaining to electrical safety',
    sortOrder: 19,
    id: {
      staging: '7132d576-a48f-4e78-b512-dcf72e3740b8',
      prod: '15069e7d-9eef-4ea5-84a2-b5d37211c77a',
    },
    synonyms: [
      'Electrical Installation',
      'Electrical Repair',
      'Electrical Maintenance',
      'Electrical Services',
      'Electrician',
      'Electric',
      'Electrial',
      'Electrcial',
      'Electrical Contractors',
      'Electrical Wiring',
      'Lighting Installation',
      'Electrical Panel Upgrades',
      'Electrical Safety',
      'Electrical Troubleshooting',
      'Electrical Maintenance Services',
      'Emergency Electrical Services',
    ],
  },
  communications: {
    name: 'Communications',
    icon: null,
    urlKey: 'communications',
    description:
      'Communications contractors specialize in installing, altering, upgrading and maintaining telecommunications equipment in homes and businesses. They provide communication services whether it is packages for internet, cell phone or TV services. That is why they need expert expertise in telecommunications. These individuals usually install or modify telephone systems in business buildings or apartment buildings. They also install and modify such equipment in residences.',
    sortOrder: 20,
    id: {
      staging: '04927e05-5858-45bb-92c7-aa218caedab5',
      prod: 'bf26c799-48f8-468b-8f0d-d2c6e64d46f0',
    },
    synonyms: [
      'Telecommunications',
      'Networking',
      'Data Communications',
      'Information Technology',
      'Comminications',
      'Communicatios',
      'Comunications',
      'Communication Services',
      'Networking Solutions',
      'IT Support',
      'Network Installation',
      'Data Cabling',
      'Telecommunications Equipment',
      'Business Communications',
      'Wireless Networking',
    ],
  },
  electronic_security: {
    name: 'Electronic Security',
    icon: null,
    urlKey: 'electronic-security',
    description:
      'Electronic security systems provide a layer of protection around your home or business. They prevent unauthorized access to technologies, systems, or networks. Electronic systems also alert you to unusual activity and send alerts to your correspondents when suspicious items or people are detected around the premises. Unfortunately, there is a lot of misinformation out there about electronic security systems and how they work. ',
    sortOrder: 21,
    id: {
      staging: 'db194db5-b11f-4705-932d-1710ac36b80c',
      prod: '5606c4f0-91df-468a-815c-eced7c44c39a',
    },
    synonyms: [
      'Access Control',
      'Surveillance',
      'Alarm Systems',
      'Security Systems',
      'Electronic Secuirty',
      'Electronic Secuirty',
      'Security Cameras',
      'Video Surveillance',
      'Access Control Systems',
      'Intrusion Detection',
      'Security Alarms',
      'Electronic Locks',
      'Burglar Alarms',
      'Security Consultancy',
    ],
  },
  electronic_life_safety_: {
    name: 'Electronic Safety',
    icon: null,
    urlKey: 'electronic-safety',
    description:
      'Electronic safety systems provide a layer of protection around your home or business. They prevent unauthorized access to technologies, systems, or networks. Electronic systems also alert you to unusual activity and send alerts to your correspondents when suspicious items or people are detected around the premises. Unfortunately, there is a lot of misinformation out there about electronic security systems and how they work.',
    sortOrder: 22,
    synonyms: [
      'Safety Systems',
      'Emergency Systems',
      'Safety Equipment',
      'Safety Devices',
      'Electronic Safty',
      'Electronic Saftey',
      'Safety Equipment Suppliers',
      'Safety System Installers',
      'Emergency Response Systems',
      'Personal Protective Equipment',
      'Safety Training',
      'Safety Consulting',
      'Industrial Safety',
      'Safety Device Manufacturers',
      'electronic-safety',
      'electronic_safety',
    ],
  },
  flooring: {
    name: 'Flooring',
    icon: '/provided-services/flooring.jpg',
    urlKey: 'flooring',
    description: null,
    sortOrder: 23,
    synoynms: [],
  },
  siding: {
    name: 'Siding',
    icon: null,
    urlKey: 'siding',
    description: null,
    sortOrder: 23,
    synoynms: [],
  },
  tile: {
    name: 'Tile',
    icon: null,
    urlKey: 'tile',
    description: null,
    sortOrder: 23,
    synoynms: [],
  },
  windows: {
    name: 'Windows',
    icon: null,
    urlKey: 'windows',
    description: null,
    sortOrder: 23,
    synoynms: [],
  },
  countertops: {
    name: 'Countertops',
    icon: null,
    urlKey: 'countertops',
    description: null,
    sortOrder: 23,
    synoynms: [],
  },
  scaffolding: {
    name: 'Scaffolding',
    icon: null,
    urlKey: 'scaffolding',
    description: null,
    sortOrder: 23,
    synoynms: [],
  },
  metals: {
    name: 'Metals',
    icon: null,
    urlKey: 'metals',
    description: null,
    sortOrder: 23,
    synoynms: [],
  },
  waterproofing: {
    name: 'Waterproofing',
    icon: null,
    urlKey: 'waterproofing',
    description: null,
    sortOrder: 23,
    synoynms: [],
  },
  cleaning_services: {
    name: 'Cleaning Services',
    icon: null,
    urlKey: 'cleaning-services',
    description: null,
    sortOrder: 23,
    synoynms: [],
  },
  equipment_rental: {
    name: 'Equipment Rental',
    icon: null,
    urlKey: 'equipment-rental',
    description: null,
    sortOrder: 23,
    synoynms: [],
  },
};

const iterableProvidedServices = Object.values(providedServices).sort(
  (a, b) => a.sortOrder - b.sortOrder
);

const alphabeticalProvidedServices: ProvidedServiceType[] = Object.values(
  providedServices
).sort((a, b) => a.name.localeCompare(b.name));

export {
  alphabeticalProvidedServices,
  providedServices,
  iterableProvidedServices,
};
